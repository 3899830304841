// src/store/homeSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchTestimonialData = createAsyncThunk(
  "home/fetchTestimonials",
  async () => {
    const res = await fetch("https://dev.spreeh.com/apps/testimonials");
    const data = await res.json();
    return data;
  }
);

export const testimonialSlice = createSlice({
  name: "packages",
  initialState: {
    item: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonialData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTestimonialData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.item = action.payload;
      })
      .addCase(fetchTestimonialData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default testimonialSlice.reducer;
