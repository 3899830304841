// src/store/homeSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchHomeData = createAsyncThunk(
  "home/fetchHomeData",
  async () => {
    const res = await fetch("https://dev.spreeh.com/apps/home");
    const data = await res.json();
    return data;
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    bannerData: [],
    aboutUs: null,
    hashtagImg: null,
    calculatorImg: null,
    imgGrid: [],
    categoryData: [],
    servicesData: [],
    topVendorsData: [],
    socialMediaData: null,
    faqData: [],
    occasionData: [],
    videoData: [],
    topCitiesData: [],
    countryCodes: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHomeData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.aboutUs = action.payload.aboutus;
        state.bannerData = action.payload.banner;
        state.hashtagImg =
          action.payload.ourwork || action.payload.ourwork > 0
            ? action?.payload?.ourwork[0]
            : null;
        state.calculatorImg =
          action.payload.ourwork || action.payload.ourwork > 0
            ? action?.payload?.ourwork[1]
            : null;
        state.imgGrid = action.payload.ourwork;
        state.categoryData = action.payload.categories;
        state.servicesData = action.payload.services;
        state.topVendorsData = action.payload.topvendors;
        state.socialMediaData = action.payload.socialmedia;
        state.faqData = action.payload.faqs;
        state.occasionData = action.payload.occasions;
        state.videoData = action.payload.videourl;
        state.topCitiesData = action.payload.topCities;
        state.countryCodesData = action.payload.countryCodes;
      })
      .addCase(fetchHomeData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default homeSlice.reducer;
