import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import celebrate from "../../../assets/images/celebrate.png";
import connect from "../../../assets/images/connect.png";
import getCustomizedPro from "../../../assets/images/getCustomizedPro.png";
import shareYourReq from "../../../assets/images/shareYourReq.png";
import SectionLabel from "../../../components/SectionLabel";
import InfoQuoteNumbers from "./InfoQuoteNumbers";

const QuoteNumbers = ({ showInfoQuotes }) => {
  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  return (
    <div className="w-full bg-white pt-14">
      <div className="mb-4" data-aos="fade-up">
        <div className="hidden md:block">
          {" "}
          <SectionLabel text={"Get Your Free Competitive Quote Today"} />
        </div>
        <div className="block md:hidden">
          {" "}
          <SectionLabel
            text={"Simplify your Celebrations with Us"}
            styles={{
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          />
        </div>
        <div className="w-full text-center">
          <p className="hidden md:block font-medium text-sm mt-2 font-['Manrope']">
            Elevate your celebrations in just a few clicks with Spreeh's trusted
            vendor network
          </p>
          <p className="block md:hidden font-medium text-xs mt-2 mx-14 font-['Manrope']">
            Plan an event with us in just a few clicks.
            <br /> Choose from our endless trusted vendor options.
          </p>
        </div>
        <div className="w-full md:max-w-[1050px] grid grid-cols-2 md:grid-cols-4 gap-4 my-8 px-6 md:px-28 mx-auto justify-center items-center pt-8">
          <div
            className="flex flex-col justify-center items-center mt-3 md:mt-0"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={shareYourReq}
              className="w-[94px] h-[94px] md:w-[102px] md:h-[94px]"
              alt="icon1"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Share your <br />
                requirements
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={getCustomizedPro}
              className="w-[80px] h-[70px] md:w-[80px] md:h-[70px] mt-7 mb-2"
              alt="icon2"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Get customised <br />
                proposals
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center mt-6 md:mt-2 md:mt-0"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={connect}
              className="w-[80px] h-[80px] md:w-[80px] md:h-[80px]"
              alt="icon3"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Connect
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center mt-4"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img src={celebrate} className="w-[90px] h-[90px]" alt="icon4" />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Celebrate <br /> <p className="hidden md:block"> with Ease</p>
              </p>
            </div>
          </div>
        </div>

        {showInfoQuotes && (
          <div className="mt-20 bg-custom-pink-gradient w-full h-72 md:h-56 md:flex justify-center text-center gap-x-0 gap-y-10 text-white items-center">
            <InfoQuoteNumbers />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteNumbers;
