import React from "react";
import {
  FaCalendarAlt,
  FaUsers,
  FaMapMarkerAlt,
  FaStar,
  FaMusic,
  FaRupeeSign,
} from "react-icons/fa";

const Profile = () => {
  const iconStyle = { color: "#E57796", marginRight: "8px" };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-[900px] flex flex-col justify-center items-start">
        <div className="flex items-center mb-4">
          <img
            src="https://s3-alpha-sig.figma.com/img/d6d1/7e7c/6820b0a1ee0d0153dda42cfb80e43e02?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CuNuM-~YjWOTpCS6z6sQJXhVHPc3GrnFxqL3wLGEoMNS9MQhmgvbY~KX2nsJ53UHwowZ5aXTqEY-jZvm-FlanHG3Sd7Sc6ZMRCiy-TmhAA0c5z1QinsiExGuZfl6jxAzmj9WKChCSB787DOg7Kf06BzM62ovMts5krEmEUlLi1WWlDLBqMj4GOZvedpF25ciyHWtLuMT9JaEjREtfBSnnnzRZs7c3EuIx-GD1IotfX6Kp5SpOoxL2JiO6nWuoGTHc1IAmZvqSPXAZutiOmVEO4gIZCummUeBTRDLo6AwFe8kLJxfByuw3X9K2BmAIUljljovgUmvOjabpILEUfwiww__"
            alt="Profile"
            className="w-16 h-16 rounded-full mr-4"
          />
          <div>
            <h1
              className="text-xl font-light text-gray-500"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
              }}
            >
              Welcome to Spreeh
            </h1>
            <h2
              className="text-4xl font-semibold"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              Mr. Punit Shah
            </h2>
          </div>
        </div>

        <div className="w-full text-center flex justify-center items-center h-10 bg-gradient-to-r from-[#E57796] to-[#EE978D] p-4 rounded-lg text-white mb-6">
          <p>Your Dream event begins from here...</p>
        </div>

        <div className="grid grid-cols-2 gap-y-5 gap-x-1 w-full">
          <div className="flex items-center">
            <FaCalendarAlt style={iconStyle} />
            <p className="font-medium">Event Date: 19th December 2025</p>
          </div>
          <div className="flex items-center">
            <FaStar style={iconStyle} />
            <p className="font-medium">
              Events: Sangeet, Mehendi, Haldi, Wedding, Reception
            </p>
          </div>
          <div className="flex items-center">
            <FaUsers style={iconStyle} />
            <p className="font-medium">Guests: 500-750 guests</p>
          </div>
          <div className="flex items-center">
            <FaMusic style={iconStyle} />
            <p className="font-medium">
              Services: Decor, Singer, Caterer, Creatives
            </p>
          </div>
          <div className="flex items-center">
            <FaMapMarkerAlt style={iconStyle} />
            <p className="font-medium">Venue: Panjim, Goa</p>
          </div>
          <div className="flex items-center">
            <FaRupeeSign style={iconStyle} />
            <p className="font-medium">Budget: ₹ 3,00,000 - ₹ 5,00,000</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
