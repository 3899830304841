import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import businessAnalitics from "../../../assets/images/businessAnalitics.png";
import increasedBusiness from "../../../assets/images/increasedBusiness.png";
import standardCommission from "../../../assets/images/standardCommision.png";
import steamLinedProcess from "../../../assets/images/steamLinedProcess.png";
import SectionLabel from "../../../components/SectionLabel";

const BecomePartnerQuote = ({ showInfoQuotes }) => {
  const img1 = standardCommission;
  const img2 = steamLinedProcess;
  const img3 = businessAnalitics;
  const img4 = increasedBusiness;

  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  return (
    <div className="w-full bg-white pt-28">
      <div className="mb-4" data-aos="fade-up">
        <div className="hidden md:block">
          {" "}
          <SectionLabel text={"Perks of partnering with Spreeh"} />
        </div>
        <div className="block md:hidden">
          {" "}
          <SectionLabel
            text={"Perks of partnering with Spreeh"}
            styles={{
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          />
        </div>
        <div className="w-full text-center">
          <p className="hidden md:block font-medium text-sm mt-2 font-['Manrope']">
            Unlock new opportunities and expand your clientele by partnering
            with Spreeh.
          </p>
          <p className="block md:hidden font-medium text-xs mt-2 mx-14 font-['Manrope']">
            Unlock new opportunities and expand your
            <br /> clientele by partnering with Spreeh.
          </p>
        </div>
        <div className="w-full md:max-w-[1050px] grid grid-cols-2 md:grid-cols-4 gap-4 my-8 px-6 md:px-28 mx-auto justify-center items-center pt-8">
          <div
            className="flex flex-col justify-center items-center mt-3 md:mt-0"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={img1}
              className="w-[84px] h-[84px] md:w-[84px] md:h-[84px]"
              alt="icon1"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Standardised <br />
                commission
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={img2}
              className="w-[84px] h-[84px] md:w-[84px] md:h-[84px]"
              alt="icon2"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Streamlined <br />
                process
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center mt-4 md:mt-2 md:mt-0"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img
              src={img3}
              className="w-[84px] h-[84px] md:w-[84px] md:h-[84px]"
              alt="icon3"
            />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Business <br /> Analytics
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center mt-2 items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img src={img4} className="w-[84px] h-[84px]" alt="icon4" />
            <div className="w-full text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Increased <br /> Business
              </p>
            </div>
          </div>
        </div>

        {showInfoQuotes && (
          <div className="mt-20 hidden bg-custom-pink-gradient w-full h-56 md:flex justify-center gap-20 text-white items-center">
            {/* <InfoQuoteNumbers /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default BecomePartnerQuote;
