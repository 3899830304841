import Aos from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const CRYPTO_JS = require("crypto-js");
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

function computeHmacSha256(data, key) {
  const keys = Object.keys(data).sort();
  const sortedObject = {};
  keys.forEach((key) => {
    sortedObject[key] = data[key];
  });
  let dataString = "";
  for (let key in sortedObject) {
    const value = sortedObject[key];
    if (Array.isArray(value) || typeof value === "object") {
      dataString += CRYPTO_JS.SHA256(JSON.stringify(value)).toString(
        CRYPTO_JS.enc.Hex
      );
    } else {
      dataString += value;
    }
  }
  const hash = CRYPTO_JS.HmacSHA256(dataString, key);
  return CRYPTO_JS.enc.Hex.stringify(hash);
}

const ContactUs = () => {
  // toast.configure();
  const formik = useFormik({
    initialValues: {
      fullName: "",
      subject: "",
      phoneNumber: "",
      message: "",
      sendWhatsapp: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      subject: Yup.string().required("Subject is required"),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .length(10, "Phone number must be exactly 10 numbers")
        .matches(/^[6-9]\d{9}$/, "Phone number is not valid"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values) => {
      const { fullName, phoneNumber, subject, message } = values;
      const requestBody = {
        firstName: fullName,
        lastName: "",
        mobile: phoneNumber,
        email: "",
        subject: subject,
        message: message,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      try {
        const response = await fetch("https://dev.spreeh.com/apps/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Hash": hashValue,
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          toast.success("Details sended successfully!", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("Submission failed");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to submit detials. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  return (
    <div className="w-full pt-11 min-h-screen bg-white">
      <div className="mt-20 mb-1 flex justify-center align-center">
        <div className="flex flex-row w-full justify-center items-center h-full">
          <div className="flex flex-col items-center md:mx-0">
            <h2
              className="text-base text-center md:text-xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Contact"}
            </h2>
            <h2
              className="text-5xl text-center md:text-5xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Spreeh"}
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full bg-white flex justify-center " data-aos="fade-up">
        <div className="w-full max-w-[1024px] h-[640px] justify-center  md:shadow-lg flex flex-col md:flex-row">
          {/* Second Section - Form */}
          <div className="w-full md:w-[55%] h-full flex flex-col items-center justify-center p-8">
            <form onSubmit={formik.handleSubmit} className="w-full mt-8">
              {/*Full Name */}
              <div className="mb-6 text-sm" data-aos="fade-bottom">
                <label
                  htmlFor="fullName"
                  className="block text-sm font-semibold text-gray-700"
                >
                  Full Name
                </label>
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                    formik.touched.fullName || formik.submitCount > 0
                      ? formik.errors.fullName
                        ? "border-red-500 bg-white"
                        : "border-light-pink bg-white"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  placeholder="Enter your full name"
                />
                {(formik.touched.fullName || formik.submitCount > 0) &&
                formik.errors.fullName ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.fullName}
                  </div>
                ) : null}
              </div>
              {/* Phone Number */}
              <div className="mb-6 text-sm" data-aos="fade-bottom">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-semibold text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                    formik.touched.phoneNumber || formik.submitCount > 0
                      ? formik.errors.phoneNumber
                        ? "border-red-500 bg-white"
                        : "border-light-pink bg-white"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  placeholder="Enter your phone number"
                />
                {(formik.touched.phoneNumber ||
                  (formik.submitCount > 0 &&
                    formik.touched.phoneNumber &&
                    formik.errors.phoneNumber)) &&
                formik.errors.phoneNumber ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.phoneNumber}
                  </div>
                ) : null}
              </div>

              {/* Subject */}
              <div className="mb-6 text-sm" data-aos="fade-bottom">
                <label
                  htmlFor="subject"
                  className="block text-sm font-semibold text-gray-700"
                >
                  Subject
                </label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                    formik.touched.subject || formik.submitCount > 0
                      ? formik.errors.subject
                        ? "border-red-500 bg-white"
                        : "border-light-pink bg-white"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  placeholder="Enter Subject"
                />
                {(formik.touched.subject || formik.submitCount > 0) &&
                formik.errors.subject ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.subject}
                  </div>
                ) : null}
              </div>

              {/* Message */}
              <div className="mb-6 text-sm" data-aos="fade-bottom">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold text-gray-700"
                >
                  Your Message
                </label>
                <input
                  id="message"
                  name="message"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                    formik.touched.message || formik.submitCount > 0
                      ? formik.errors.message
                        ? "border-red-500 bg-white"
                        : "border-light-pink bg-white"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  placeholder="Enter your message"
                />
                {(formik.touched.message || formik.submitCount > 0) &&
                formik.errors.message ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.message}
                  </div>
                ) : null}
              </div>

              {/* Whatsapp */}
              <div className="mb-6 flex items-center" data-aos="fade-bottom">
                <input
                  id="sendWhatsapp"
                  name="sendWhatsapp"
                  type="checkbox"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sendWhatsapp}
                  className="mr-2"
                />
                <label
                  htmlFor="sendWhatsapp"
                  className="text-sm font-semibold text-gray-700"
                >
                  Send me updates on{" "}
                  <span style={{ color: "green" }}>Whatsapp</span>
                </label>
              </div>

              {/* Submit Button */}
              <div className=" w-full flex justify-center" data-aos="fade-up">
                <button
                  type="submit"
                  className="text-sm px-4 text-white py-2 bg-medium-pink w-52 h-12 rounded-xl font-normal font-['Manrope'] cursor-pointer hover:bg-rose-400 transition duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-20 mb-4 flex justify-center align-center">
        <div className="flex flex-row w-full justify-center items-center h-full">
          <div className="flex flex-col items-center md:mx-0">
            <h2
              className="text-xl text-center md:text-5xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Got More Queries?"}
            </h2>
            <p className="font-['Manrope'] flex justify-center align-center text-black font-medium text-sm pb-2 pt-8">
              <div className="text-2xl pr-2">
                <MdEmail />
              </div>

              {"Write us at: "}
              <a
                href="mailto:contact@spreeh.com"
                className="font-['Manrope'] text-black font-medium text-sm pb-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@spreeh.com
              </a>
            </p>
            <p className="font-['Manrope'] flex text-black font-medium text-sm pb-10 pt-3">
              <div className="text-2xl pr-2">
                <IoLogoWhatsapp />
              </div>
              Whatsapp us at: +91 9321941846
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
