import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SectionLabel from "../../../components/SectionLabel";

const Faq = () => {
  const { faqData:faq } = useSelector((state) => state.home);

  // const [calculatorImg, setCalculatorImg] = useState(null);
  const [openIndexOne, setOpenIndexOne] = useState(null);
  const [openIndexTwo, setOpenIndexTwo] = useState(null);

  const handleToggleOne = (index) => {
    setOpenIndexOne(openIndexOne === index ? null : index);
  };

  const handleToggleTwo = (index) => {
    setOpenIndexTwo(openIndexTwo === index ? null : index);
  };

  useEffect(() => {
    // getHome();
    Aos.init({ duration: 200 });
  }, []);

  const middleIndex = Math.ceil(faq.length / 2);
  const faqForImageSection = faq.slice(0, middleIndex);
  const faqForAccordionSection = faq.slice(middleIndex);

  return (
    <div className="w-full bg-gray-100 pt-2 pb-14 mt-10">
      <div className={"mt-20 mb-4"} data-aos="fade-up">
        <SectionLabel text={"FAQs"} />
      </div>
      <div className="w-full flex justify-center my-8">
        <div className="w-[1024px] h-[90%] md:h-[90%] flex flex-col md:flex-row justify-between items-stretch">
          {/* Image Section */}
          {/* <div className="hidden md:block md:w-[40%] h-full">
            <img
              src={calculatorImg}
              alt="FAQ"
              className="w-full h-full object-cover rounded-lg"
            />
          </div> */}
          <div className="w-full md:w-[60%] px-4 md:px-8" data-aos="fade-up">
            {faqForImageSection.map((faq, index) => (
              <div
                key={index}
                className="mb-1 p-4 bg-gray-100  rounded-lg transition-all duration-300"
              >
                <div
                  className=" flex justify-between items-center cursor-pointer"
                  onClick={() => handleToggleOne(index)}
                >
                  <span className="text-sm font-semibold font-['Manrope']">
                    {faq.question}
                  </span>
                  <span className={`text-xl text-black font-semibold`}>
                    {openIndexOne === index ? "-" : "+"}
                  </span>
                </div>
                <div
                  className={`mt-2 overflow-hidden transition-max-height duration-500 ease-in-out ${
                    openIndexOne === index ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-700 text-sm font-['Manrope'] mb-4">
                    {faq.answer}
                  </p>
                </div>
                <hr />
              </div>
            ))}
          </div>
          {/* Accordion Section */}
          <div className="w-full md:w-[60%] px-4 md:px-8" data-aos="fade-up">
            {faqForAccordionSection.map((faq, index) => (
              <div
                key={index}
                className="mb-1 p-4 bg-gray-100  rounded-lg transition-all duration-300"
              >
                <div
                  className=" flex justify-between items-center cursor-pointer"
                  onClick={() => handleToggleTwo(index)}
                >
                  <span className="text-sm max-w-[80%] font-semibold font-['Manrope']">
                    {faq.question}
                  </span>
                  <span className={`text-xl text-black font-semibold`}>
                    {openIndexTwo === index ? "-" : "+"}
                  </span>
                </div>
                <div
                  className={`mt-2 overflow-hidden transition-max-height duration-500 ease-in-out ${
                    openIndexTwo === index ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-700 text-sm font-['Manrope'] mb-4">
                    {faq.answer}
                  </p>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
