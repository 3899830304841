import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner";
import Carousel from "../../../components/Carousel";
import SectionLabel from "../../../components/SectionLabel";

const VendorTestimonial = ({
  title,
  subtitle,
  showBanner,
  showButton,
  showMobile,
  data,
}) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  useEffect(() => {
    if (data) {
      const rev = data.map((item) => ({
        imgUrl: item.picture,
        imgPhUrl: item.picture_mobile,
        location: "Unknown",
        reviews: parseInt(item.rating),
        testimonial: item.message,
        client: item.name,
      }));
      setReviews(rev);
    }
  }, [data]);

  return (
    <div className="w-full bg-white">
      <div className="mt-14 md:mt-24 mb-8" data-aos="fade-up">
        <SectionLabel text={title} />

        <div className="w-full text-center" data-aos="fade-up">
          <p className="font-medium hidden md:block text-sm mt-2  font-['Manrope']">
            {subtitle}
          </p>
        </div>
        <div
          className="my-4 mt-4 flex justify-center align-center"
          data-aos="fade-right"
        >
          <Carousel reviews={reviews} vendorMode={true} />
        </div>
        {showBanner && <Banner showBtn={showButton} showMob={showMobile} />}
      </div>
    </div>
  );
};

export default VendorTestimonial;
