import React from "react";
import PrimaryButton from "../../../components/PrimaryButton";

const Feedback = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6">
      <div className="w-full max-w-[900px] flex flex-col justify-center items-start">
        <h2 className="text-lg font-semibold mb-2">Your feedback</h2>
        <div className="w-full flex flex-col justify-center items-center">
          <textarea
            className="w-full h-48 p-4 mb-4 border border-gray-300 bg-gray-100 rounded-md focus:outline-none focus:border-gray-500"
            placeholder="Add your comment..."
          />
          <PrimaryButton
            text={"Send"}
            styles={{
              width: "12rem",
              height: "3rem",
              fontSize: "16px",
              color: "#ffffff",
            }}
            handleClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
