import React, { lazy, memo, Suspense, useTransition } from "react";
import { useSelector } from "react-redux";
import Profile from "./Sections/Profile";
import ProfilePackage from "./Sections/ProfilePackage";
import Feedback from "./Sections/Feedback";

const Faq = memo(lazy(() => import("../Home/Sections/Faq")));
const ImageGrid = memo(lazy(() => import("../Home/Sections/ImageGrid")));

const CustomerProfile = () => {
  const { imgGrid } = useSelector((state) => state.home);
  const [isPending, startTransition] = useTransition();

  return (
    <div className="w-full pt-12 min-h-screen bg-white">
      <Profile />
      <ProfilePackage />
      <Feedback />
      <Suspense fallback={<div>Loading...</div>}>
        <ImageGrid images={imgGrid.slice(2, 8)} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Faq />
      </Suspense>
    </div>
  );
};

export default CustomerProfile;
