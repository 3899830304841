import BookVendorBck from "../assets/images/BookVendorBck.png";
import PrimaryButton from "./PrimaryButton";
const Banner = ({ showBtn, showMob }) => {
  return (
    <div
      className={`relative w-full mt-14 mb-8 ${
        showMob ? "block md:block" : "hidden md:block"
      } `}
    >
      {/* Image */}
      <img
        src={BookVendorBck}
        alt="banner"
        className="w-full h-[330px] object-cover"
      />

      {/* Overlay */}
      <div className="absolute inset-0 flex items-center justify-center px-8 md:px-20">
        {/* Text */}
        <div className="flex flex-col gap-8">
          <div
            className="text-[#fff] text-3xl font-normal text-center flex justify-center md:justify-center md:text-4xl mb-2"
            style={{
              fontFamily: "Tiro Devanagari Sanskrit, serif",
              fontWeight: 400,
              fontStyle: "italic",
            }}
          >
            Book top vendors through Spreeh
          </div>
          <div className="text-white flex justify-center text-center md:text-center align-center items-center md:justify-start text-xs md:text-base w-full md:w-[800px] font-['Manrope']">
            Explore the limitless choices Spreeh offers and let us take care of
            all the coordination. From managing online communication to ensuring
            seamless service, we handle every detail so you can fully enjoy your
            event.
          </div>

          {showBtn && (
            <div
              className={`${
                showMob
                  ? "flex justify-center mt-28 md:mt-20 md:justify-start"
                  : "md:mt-20"
              } `}
            >
              <PrimaryButton
                text={"Get in touch"}
                styles={{
                  width: "18rem",
                  height: "3rem",
                  fontSize: "16px",
                  color: "#ffffff",
                }}
                handleClick={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
