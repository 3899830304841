import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import img2 from "../../../assets/images/service_img2.png";
import img3 from "../../../assets/images/service_img3.png";
import img4 from "../../../assets/images/service_img4.png";
import img1 from "../../../assets/images/serviceimg.png";
import PrimaryButton from "../../../components/PrimaryButton";
import SectionLabel from "../../../components/SectionLabel";

const Conceairge = ({ setShowModal, isButtonDisable }) => {
  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  return (
    <div className="w-full p-2 " data-aos="fade-up">
      <div className="mt-14 md:mt-28 mb-4">
        <SectionLabel
          text={"Concierge at your Service"}
          styles={{
            marginLeft: "4.5rem",
            marginRight: "4.5rem",
          }}
        />
        <div className="w-full flex justify-center text-center">
          <p className="hidden md:block font-medium text-sm md:font-sm mt-2 w-[300px] md:w-[600px] font-['Manrope']">
            Planning an event? Leave the details to us. Our dedicated team is
            ready to assist you every step of the way. Simply message us your
            requirements or connect with us by phone.
          </p>
          <p className="block md:hidden font-medium text-xs md:font-sm mt-2 w-[300px] md:w-[500px] font-['Manrope']">
            Planning an event? Let us handle the details! Our dedicated
            concierge team is here to assist you every step of the way. Message
            us your requirements and connect with us over call!
          </p>
        </div>
        <div
          className="w-full md:max-w-[1050px] grid grid-cols-2 md:grid-cols-4 gap-4 my-8 px-6 md:px-28 mx-auto justify-center items-center pt-8"
          data-aos="zoom-in"
          data-aos-easing="ease-in-sine"
        >
          <div className="flex flex-col justify-center items-center">
            <img src={img1} className="w-[80px] h-[76px]" alt="icon1" />
            <div className="w-20 text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Personalised Assistant
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img src={img2} className="w-[80px] h-[76px]" alt="icon2" />
            <div className="w-20 text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Hassle-free Experience
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <div className="w-[80px] h-[76px] bg-white flex mt-4 md:mt-0 flex-col justify-end items-center">
              <img
                src={img3}
                className="w-[80px] h-[76px] object-contain"
                alt="icon3"
              />
            </div>
            <div className="w-20 text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Trusted Vendors
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center mt-4 md:mt-0 items-center"
            data-aos="zoom-in"
            data-aos-easing="ease-in-sine"
          >
            <img src={img4} className="w-[80px] h-[76px]" alt="icon4" />
            <div className="w-20 text-center">
              <p className="font-semibold text-sm mt-2 font-['Manrope']">
                Unlimited Options
              </p>
            </div>
          </div>
        </div>
        {!isButtonDisable && (
          <div
            className="flex w-full justify-center mt-14 md:mt-20 mb-12 text-white"
            data-aos="fade-up"
          >
            <PrimaryButton
              text={"Contact Concierge"}
              styles={{
                width: "200px",
                height: "50px",
                fontSize: "14px",
              }}
              handleClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Conceairge;
