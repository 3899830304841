/* eslint-disable jsx-a11y/img-redundant-alt */
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const AboutImageGrid = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 200 });
  }, []);

  return (
    <>
      {/* Desktop Grid Layout */}
      <div className="hidden md:grid w-full gap-2 grid-cols-10 px-1 my-8">
        <div className="col-span-3 h-[491px]">
          <img
            src={data[0]}
            alt="Image 1"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="col-span-2 h-[491px]">
          <img
            src={data[1]}
            alt="Image 2"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="col-span-2 h-[491px]">
          <img
            src={data[2]}
            alt="Image 3"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="col-span-3 h-[491px]">
          <img
            src={data[3]}
            alt="Image 4"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Mobile Grid Layout */}
      <div
        className="block md:hidden w-full gap-2 grid grid-rows-5 grid-flow-col px-1 my-8"
        data-aos="fade-up"
      >
        <div className="row-span-3 col-span-1 h-70">
          <img
            src={data[0]}
            alt="Image 1"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-2 col-span-1 h-40">
          <img
            src={data[1]}
            alt="Image 2"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-2 col-span-1 h-40">
          <img
            src={data[2]}
            alt="Image 3"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-3 col-span-1 h-70">
          <img
            src={data[3]}
            alt="Image 4"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </div>
    </>
  );
};

export default AboutImageGrid;
