import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Router from './routes';
import { fetchBlogsData } from './store/blogsSlice';
import { fetchHomeData } from './store/homeSlice';
import { fetchPackagesData } from './store/packagesSlice';
import { fetchTestimonialData } from './store/testimonialSlice';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomeData());
    dispatch(fetchPackagesData());
    dispatch(fetchTestimonialData());
    dispatch(fetchBlogsData());
  }, [dispatch]);

  return (
    <h1 className="w-full min-h-screen bg-white">
      <Header />
      <Router />
      <Footer />
    </h1>
  );
}

export default App;
