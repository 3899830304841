import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Services } from "./pages/Services/Services";
import { Page404 } from "./pages/Other/Page404";
import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions";
import ScrollToTop from "./components/ScrollToTop";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
import BecomePartner from "./pages/BecomePartener/BecomePartner";
import Career from "./pages/Career/Career";
import CareerJob from "./pages/Career/CareerJob";
import CustomerProfile from "./pages/CustomerProfile/CustomerProfile";

const AppLayout = () => (
  <>
    <ScrollToTop />
    <Outlet />
  </>
);
export default function Router() {
  return useRoutes([
    {
      element: <AppLayout />,
      children: [
        {
          // path: "/",
          children: [
            // { path: "", element: <Navigate to="/" replace /> },
            {
              path: "/",
              element: <Home />,
            },
            {
              path: "/services",
              element: <Services />,
            },
            {
              path: "/terms",
              element: <TermsAndConditions />,
            },
            {
              path: "/privacy",
              element: <PrivacyPolicy />,
            },
            {
              path: "/contact-us",
              element: <ContactUs />,
            },
            {
              path: "/about-us",
              element: <AboutUs />,
            },
            {
              path: "/partner-with-us",
              element: <BecomePartner />,
            },
            {
              path: "/careers",
              element: <Career />,
            },
            {
              path: "/careerJob",
              element: <CareerJob />,
            },
            {
              path: "/customer-profile",
              element: <CustomerProfile />,
            },

            { path: "404", element: <Page404 /> },
            { path: "*", element: <Navigate to="/404" /> },
          ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
